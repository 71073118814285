import axios from "axios"
 
export const api = axios.create({
  headers: {
    Authorization : `Bearer ${localStorage.getItem("access_token")}`, 
    },
    withCredentials: false,
    //baseURL: window.location.protocol + "//"+ window.location.hostname +":8000/api",
    //baseURL: "https://servizi-test.nemea.cloud/api", 
    baseURL: process.env.REACT_APP_BASE_URL
})

 
export const down = axios.create({
  headers: {
    Authorization : `Bearer ${localStorage.getItem("access_token")}`,
    'Content-Type': 'multipart/form-data',
    },
    withCredentials: false,
    //baseURL: window.location.protocol + "//"+ window.location.hostname +":8000/api",
    //baseURL: "https://servizi-test.nemea.cloud/api",
    //baseURL: "http://servizi-test.localhost/api",
    baseURL: process.env.REACT_APP_BASE_URL,
    responseType: 'arraybuffer',
})



// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})